

.about-container{
    display: flex;
    justify-content: center;
    height: 12rem;
 
}

.about-container ul li{
    margin-top: 10px;
}

@media only screen and (max-width: 480px){
    .about-container{
        display: flex;
        justify-content: center;
        height: 11rem;
     
    }
    
    .about-container ul li{
        margin-top: 10px;
    }
}