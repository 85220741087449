
.home-container {
  margin-left: 8em;
  margin-right: 8em;
  margin-top: 10em;
}
.main-container .cover-container {
  display: flex;
  align-items: center;
}
.main-container {
  /* height: 52rem; */
  background-color: bisque;
  padding-bottom: 10rem;
}

.home-container .intro-section {
  font-size: 3.5rem;
  font-weight: bold;
}

.home-container .intro-section .dark-color {
  color: #4070f4;
}

.home-container .intro-section .resume-button {
  margin: 90px 0;
  width: 9em;
  height: 3em;
  background-color: #4070f4;
  font-size: 15px;
  border-radius: 40px;
}

.home-container
  .intro-section
  .social-container
  .social-item
  .social-anchor
  :hover {
  width: 48px;
}
.main-container .cover-container .width-container{
    width: 350px;
}
/* Mobile Style */
@media only screen and (max-width: 480px){
    .home-container {
        margin-left: 1rem;
        margin-top: 10rem;
        margin-right: 0rem;
      }
      .main-container .cover-container {
        display: flex;
        align-items: center;
      }
      .main-container {

        /* height: 50rem; */
        width: 100%;
        background-color: bisque;
      }
      
      .home-container .intro-section {
        font-size: 3.5rem;
        font-weight: bold;
      }
      
      .home-container .intro-section .dark-color {
        color: #4070f4;
      }
      
      .home-container .intro-section .resume-button {
        margin: 30px 0;
        width: 9em;
        height: 3em;
        background-color: #4070f4;
        font-size: 15px;
        border-radius: 40px;
      }
      
      .home-container
        .intro-section
        .social-container
        .social-item
        .social-anchor
        :hover {
        width: 48px;
      }  
      .main-container .cover-container .width-container{
        width: 350px;
    }
}
